import React from 'react'
import Head from '@components/Head'
import {
  Hero,
  DataAnalyticsSection,
  MicrosoftCloudSection,
  BusinessApplicationsSection,
  Microsoft365Section,
  AIServicesSection
} from '@components/pages/servicesv2'
import { CTA } from '@components/pages/about'

const ServicesPage = () => {
  return (
    <>
      <Head
        title="Data Analytics, Microsoft Cloud, Microsoft 365 and Business Application Services Toronto"
        description="MRS: A Toronto software company offering end-to-end services in cloud, data analytics, Microsoft 365, SharePoint, Office 365, Power BI, app development and more."
      />
      <Hero />
      <DataAnalyticsSection />
      <MicrosoftCloudSection />
      <BusinessApplicationsSection />
      <Microsoft365Section />
      <AIServicesSection />
      <CTA />
    </>
  )
}

export default ServicesPage
